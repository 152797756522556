<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />
            <b-button
              variant="primary"
              @click="export2excel"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1">
              <span class="text-nowrap">{{ $t('list.export') }}</span>
            </b-button>
            <b-button
              :to="{
                name:
                  'apps-' +
                  $t(this.RESOURCES_ELEMENT_NAME + '.module_name') +
                  '-create',
              }"
              variant="primary">
              <span class="text-nowrap">{{
                $t(RESOURCES_ELEMENT_NAME + '.object.add')
              }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
        <!-- column: name -->
        <template #cell(identity)="data">
          <b-badge
            pill
            class="text-capitalize">
            {{ data.item.identity }}
          </b-badge>
        </template>
        <!-- Column: key -->
        <template #cell(first_name)="data">
          {{ data.item.first_name }}
        </template>
        <template #cell(first_surname)="data">
          {{ data.item.first_surname }}
        </template>
        <!-- Column: Status -->
        <!-- Column: actions -->
        <template #cell(actions)="data">
          <b-col class="flex flex-row flex-wrap">
            <b-button
              style="margin: 1px"
              variant="primary"
              :id="get_view_button_id(data.item.id)"
              :to="{
                name:
                  'apps-' +
                  $t(RESOURCES_ELEMENT_NAME + '.module_name') +
                  '-view',
                params: { id: data.item.id },
              }">
              <feather-icon icon="FileTextIcon" />
            </b-button>

            <b-button
              style="margin: 1px"
              variant="primary"
              :id="get_edit_button_id(data.item.id)"
              :to="{
                name:
                  'apps-' +
                  $t(RESOURCES_ELEMENT_NAME + '.module_name') +
                  '-edit',
                params: { id: data.item.id },
              }">
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              style="margin: 1px"
              variant="primary"
              :id="get_medical_history_id(data.item.id)"
              :to="{
                name: 'apps-medical-history-view',
                params: {
                  case_id: data.item.case_id,
                  patient_id: data.item.id,
                },
              }">
              <feather-icon icon="BookIcon" />
            </b-button>

            <!-- ? DETAILS -->
            <b-tooltip
              :target="get_view_button_id(data.item.id)"
              :title="$t('actions.details')"
              triggers="hover"
              :noninteractive="true"
              placement="top">
            </b-tooltip>
            <!-- ? EDIT -->
            <b-tooltip
              :target="get_edit_button_id(data.item.id)"
              :title="$t('actions.edit')"
              triggers="hover"
              :noninteractive="true"
              placement="top">
            </b-tooltip>
            <!-- ? MEDICAL -->
            <b-tooltip
              :target="get_medical_history_id(data.item.id)"
              :title="$t('actions.medical_history')"
              triggers="hover"
              :noninteractive="true"
              placement="top">
            </b-tooltip>
          </b-col>
        </template>
      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Filters from './filters.vue'
import store from '@/store'
import router from '@/router'
import ElementStoreModule from '../storeModule'
import * as XLSX from 'xlsx'
import moment from 'moment'

export default {
  components: {
    Filters,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      currentPath: window.location.pathname,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      tableColumns: [],
      tableColumnsExcel: [],
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'patient'
    this.ELEMENT_APP_STORE_MODULE_NAME =
      'app-pat-li-' + this.RESOURCES_ELEMENT_NAME
    this.tableColumns = [
      {
        key: 'identity',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.identity'),
        sortable: true,
      },
      {
        key: 'first_name',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.first_name'),
        sortable: true,
      },
      {
        key: 'second_name',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.second_name'),
        sortable: true,
      },
      {
        key: 'first_surname',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.first_surname'),
        sortable: true,
      },
      {
        key: 'second_surname',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.second_surname'),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.actions'),
        thStyle: 'width: 300px;',
      },
    ]
    this.tableColumnsExcel = [
      {
        key: 'document_type_value',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.documentType'),
        sortable: true,
      },
      {
        key: 'identity',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.identity'),
        sortable: true,
      },
      {
        key: 'first_name',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.first_name'),
        sortable: true,
      },
      {
        key: 'second_name',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.second_name'),
        sortable: true,
      },
      {
        key: 'first_surname',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.first_surname'),
        sortable: true,
      },
      {
        key: 'second_surname',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.second_surname'),
        sortable: true,
      },
      {
        key: 'birthdate',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.birthdate'),
        sortable: true,
      },
      {
        key: 'age',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.age'),
        sortable: true,
      },
      {
        key: 'eapb_eps_value',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.eapb_eps'),
        sortable: true,
      },
      {
        key: 'employment_value',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.employment'),
        sortable: true,
      },
      {
        key: 'contact_numbers_value',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.contact_numbers'),
        sortable: true,
      },
      {
        key: 'contact_emails_value',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.contact_emails'),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.actions'),
        thStyle: 'width: 300px;',
      },
    ]
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )
    var data = JSON.parse(sessionStorage.getItem(this.currentPath))

    data && (this.query = data.query)
    await this.fetchElements()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  watch: {
    perPage: function (val) {
      this.fetchElements()
    },
    currentPage: function (val) {
      this.fetchElements()
    },
    query: function (val) {
      this.currentPage = 1
      this.fetchElements()
    },
  },
  methods: {
    get_view_button_id(key) {
      return 'view_button' + key
    },
    get_edit_button_id(key) {
      return 'edit_button' + key
    },
    get_medical_history_id(key) {
      return 'medical_history' + key
    },
    filterJson(jsonData) {
      var filterList = this.tableColumnsExcel.map(function (a) {
        return a.key
      })
      filterList.pop()
      return jsonData.map((v) =>
        filterList.map((j) => {
          return v[j]
        })
      )
    },
    getHeader() {
      const res = {}
      for (var i = 0; i < this.tableColumnsExcel.length; i++) {
        res[this.tableColumnsExcel[i].key] = this.tableColumnsExcel[i].label
      }
      return res
    },
    format(jsonData) {
      for (var i = 0; i < jsonData.length; i++) {
        jsonData[i].birthdate = moment(jsonData[i].birthdate).format(
          'DD/MM/YYYY'
        )
        jsonData[i].age = moment().diff(jsonData[i].birthdate, 'years')
      }
    },
    async export2excel() {
      const filename = this.$t(this.RESOURCES_ELEMENT_NAME + '.object_name')
      var result = await store.dispatch(
        this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_excel_elements',
        {
          queryParams: {
            perPage: 10000,
            page: 1,
            query: this.query,
          },
        }
      )
      this.format(result.data.items)
      result.data.items.unshift(this.getHeader())
      let list = this.filterJson(result.data.items)
      let data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    fetchElements() {
      var _self = this
      sessionStorage.setItem(
        this.currentPath,
        JSON.stringify({ query: this.query })
      )

      store
        .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_elements', {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            query: this.query,
          },
        })
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
